import React from 'react';

import '../About.css';

function About() {
    return (
      <>
        <div className="common-container">
          <div className="mb-4 mb-lg-5 container">
            <img
              src="./images/capsules.png"
              className="plate-img"
              width="100%"
              height="800px"
              alt="capsulecorp"
            ></img>
          </div>
          <div className="px-4 mt-md-4 container">
            <div className="mb-5 row">
              <div className="col">
                <div className="mb-4 row">
                  <div className="col">
                    <h3 className="about-header">Welcome to Capsule Corp.</h3>
                    <p>
                      Items such as vehicles, houses, and refrigerators are
                      stored within capsules. Some may contain other items
                      increasing the capsule's convenience and utility as a
                      storage device (portable shelters and vehicles which could
                      also contain emergency supplies. Comforts like kitchens,
                      baths and restroom facilities). Ideal from a logistical
                      standpoint, they allow for the easy transportation of
                      large numbers of materials.
                    </p>
                  </div>
                  <div className="my-lg-auto col-lg-4 col-12 offset-lg-1">
                    <div className="common-container">
                      <img
                        src="./images/capsule-instruct.png"
                        className="capsule-instruct"
                        width="300px"
                        height="200px"
                        alt="capsule"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 mt-md-4 container">
            <div className="mb-5 row">
              <div className="col">
                <div className="mb-4 row">
                  <h3 className="mission-header">Mission/Roadmap</h3>
                  <div className="col">
                    Our mission is to improve the lives of many who need it.
                    Capsules are distributed online and accessed across Earth.
                    It is possible to have any item turned into a capsule.
                  </div>
                </div>
                <div className="mb-4 row">
                  <div className="col">
                    Phase 1 - Feburary 20, 2022
                  </div>
                  <br></br>
                  <p>
                    Deployment to mainnet and marketplace
                  </p>
                  <br></br>
                  <div className="col">
                    Phase 2 - TBD
                  </div>
                  <br></br>
                  <p>
                    Market capsules and grow the community. Holders will be eligible for the capsule prototype airdrop *Only 500 available*. 
                  </p>
                  <br></br>
                  <div className="col">
                    Phase 3 - TBD
                  </div>
                  <br></br>
                  <p>
                    Based on community ideas, a second collection of 5000 special capsules will be released.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 mt-md-4 container">
            <div className="mb-5 row">
              <div className="col">
                <div className="mb-4 row">
                  <div className="col">
                    <h3 className="mission-header">Specs</h3>
                    <p>
                      Each Capsule is unique and programmatically generated to
                      create traits including Color, Item, Weight, Expiration,
                      Number and more. All capsules are inspired by DBZ, some
                      are rarer than others. The Capsules are stored as ERC-721
                      tokens on the Ethereum blockchain and hosted on IPFS. You
                      will need a metamask wallet to purchase!
                    </p>
                  </div>
                  <div className="my-lg-auto col-lg-4 col-12 offset-lg-1">
                    <div className="common-container">
                      <img
                        src="./images/prototype-capsule.png"
                        className="prototype-capsule"
                        width="300px"
                        height="300px"
                        alt="capsule"
                      ></img>
                    </div>
                    <p className="prototype-label">Prototype ver. 1 </p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <div className='contract-containter'>
                    <h3 className="verified-contract">Verified Contract Address:</h3>
                    <div>
                      <a className="verified-contract" href="https://etherscan.io/address/0x919bf3d406ff90c17f97cd5887adf8c8c111fa8c#writeContract">0x919bf3D406Ff90c17F97CD5887aDF8c8C111FA8C</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container-fluid foot-line">
              <div className="row mx-0 footer-padding">
                <h2 className="col-12 col-lg-4 order-lg-first my-lg-auto">C P S L</h2>
                <div className="col-12 col-lg-4 order-first">
                  <img
                    className="img-fluid footer-logo"
                    src="./images/logo.png"
                    width="100px"
                    height="100px"
                    alt="logo"
                  ></img>
                </div>
                <div className="order-last my-auto text-center col-lg-4 col-sm-12 col-12">
                  <div className="row">
                    <div className="text-lg-right col-sm-12">
                      <a href="https://www.instagram.com/cpslcorp">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="https://twitter.com/CpslCorp">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="https://t.co/9rMqjcDQam">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                    <div className="text-lg-right col-sm-12">
                      <p className="copyright text-right">
                        <span className="copy-left">© 2022 CpslCorp</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default About;