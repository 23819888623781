import React from 'react';
import '../Gallery.css';

function Gallery() {
    return (
      <>
        <div className="common-container">
          <div className="container">
            <div className="header">
              <h1>Gallery</h1>
              <p>Capsules</p>
            </div>
            <div className="row">
              <div className="column">
                <img
                  src="https://ipfs.io/ipfs/QmNTWLaFEoAP3zxz33Mc5XVu7adUg4pa4LjnTjrbvxDWom"
                  width="100%"
                  alt="1413"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmcBr6ruVUzYDBieqZ4Wf67c8iZNwSoqAYahqUi1U86M8n"
                  width="100%"
                  alt="1505"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmX6Y4358MC8ZAwwcWkY6d1iSnDwjrT5UZMhkJZBkGYt61"
                  width="100%"
                  alt="2696"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmU862vtfh1o6JHxQuxDbCd1ZN3peaUXsgh4QTG2b87yuS"
                  width="100%"
                  alt="4746"
                ></img>
              </div>
              <div className="column">
                <img
                  src="https://ipfs.io/ipfs/QmQuwKM6cKa5X5PRdzmYqUxZf3Jtv1W1pt99hxHrSXEMVG"
                  width="100%"
                  alt="514"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmWCgFqK7qQo3kQXYzg735wmyNsFg2sUukPFJ4DjGbYWiT"
                  width="100%"
                  alt="1619"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmUYpyVkv92EaDH6rtFuNZMZon9ME3idxfoZ4wjkf8dv4V"
                  width="100%"
                  alt="3251"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmXSA4uw55bxEfFzvqimSopmiujXfPVn9T5AWcqucYEz6j"
                  width="100%"
                  alt="595"
                ></img>
              </div>
              <div className="column">
                <img
                  src="https://ipfs.io/ipfs/QmSyYKZdumZu3ZPJ6M1TpW3dSTWh9dh9VjD79TnFaiA4Ee"
                  width="100%"
                  alt="638"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmWB9Besxu1yTuZvZrvAQ6fGceHEHM9xnpsmzfTipCEB4C"
                  width="100%"
                  alt="1921"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmV9pdiZCKnHsstRZSC1XWgKtHqbDHxwhkMPmCfY1c5w5Z"
                  width="100%"
                  alt="475"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmVo8tqX2kAw5D9H12wYLMzWyDtb4tQJAQmiv7q42ix2RK"
                  width="100%"
                  alt="4329"
                ></img>
              </div>
              <div className="column">
                <img
                  src="https://ipfs.io/ipfs/QmXAGsccS2AzDCKgsT9YWToSxGrE1QsobUR7WC2RceGE5z"
                  width="100%"
                  alt="1193"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmPXXDL6XSjjVWUSkVAJ8TtWfyrbrSdB449xfNbGRNB84M"
                  width="100%"
                  alt="2583"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmbTNGKxnGAcs2VRmu17VEMdmxmGupwciGnnCBLY2uyP8x"
                  width="100%"
                  alt="1075"
                ></img>
                <img
                  src="https://ipfs.io/ipfs/QmRhK9ZQaDTTgY2idwZSq6gvizwmQbhWEdJvFNKhgri3dR"
                  width="100%"
                  alt="177"
                ></img>
              </div>
            </div>
            <div>And Many More...</div>
          </div>
        </div>
        <div className="footer">
          <div className="container-fluid foot-line">
            <div className="row mx-0 footer-padding">
              <h2 className="col-12 col-lg-4 order-lg-first my-lg-auto">C P S L</h2>
              <div className="col-12 col-lg-4 order-first">
                <img className="img-fluid footer-logo" src="./images/logo.png" width="100px" height="100px" alt="logo"></img>
              </div>
              <div className="order-last my-auto text-center col-lg-4 col-sm-12 col-12">
                <div className="row">
                  <div className="text-lg-right col-sm-12">
                    <a href="https://www.instagram.com/cpslcorp">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://twitter.com/CpslCorp">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://t.co/9rMqjcDQam">
                      <i className="fab fa-discord"></i>
                    </a>
                  </div>
                  <div className="text-lg-right col-sm-12">
                    <p className="copyright text-right">
                      <span className="copy-left">© 2022 CpslCorp</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Gallery;
