import React from 'react';
import '../Footer.css';
import '../Contact.css';

function Contact() {

  return (
    <>
      <div className="common-container">
        <div className="mb-4 mb-lg-5 container">
          <img src="./images/capsule-corp-night.png" width="100%" height="600px" alt="capsulecorp" className="capsulecorp-night"></img>
        </div>
        <div className="contact-container">
          <div className="contact-info">
            <div className="left-side"></div>
            <div className="right-side">
              <h2 className="contact-us">Contact</h2>
              <h4>Email: cpslcorp@gmail.com</h4>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container-fluid foot-line">
            <div className="row mx-0 footer-padding">
              <h2 className="col-12 col-lg-4 order-lg-first my-lg-auto">C P S L</h2>
              <div className="col-12 col-lg-4 order-first">
                <img className="img-fluid footer-logo" src="./images/logo.png" width="100px" height="100px" alt="logo"></img>
              </div>
              <div className="order-last my-auto text-center col-lg-4 col-sm-12 col-12">
                <div className="row">
                  <div className="text-lg-right col-sm-12">
                    <a href="https://www.instagram.com/cpslcorp">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://twitter.com/CpslCorp">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://t.co/9rMqjcDQam">
                      <i className="fab fa-discord"></i>
                    </a>
                  </div>
                  <div className="text-lg-right col-sm-12">
                    <p className="copyright text-right">
                      <span className="copy-left">© 2022 CpslCorp</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
