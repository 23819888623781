import '../App.css';
import './HeroSection.css';
import { useState, useEffect } from 'react';
import contract from "../contract/config.json";
import React from 'react';
import { ethers } from 'ethers';


function HeroSection() {
  
  const [currentAccount, setCurrentAccount] = useState(null);
  const contractAddress = "0x919bf3D406Ff90c17F97CD5887aDF8c8C111FA8C";
  const abi = contract.abi;

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    } else {
      console.log("Wallet exists, you're ready to go!")
    }

    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts.length !== 0 ) {
      const account = accounts[0];
      console.log("Found an authorized account!");
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found!");
    }
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts'});
      console.log("Found an account!");
      setCurrentAccount(accounts[0]);
    } catch (err) {
      console.log(err)
    }
  }

  const mintNftHandler = async () => {
    try {
      const { ethereum } = window
      
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);
        
        console.log("Initialize payment");
        let nftTxn = await nftContract.mint(1, { value: ethers.utils.parseEther("0.07") });

        await nftTxn.wait();

        console.log(`Complete, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
      } else {
        console.log("Ethereum object does not exist")
      }

    } catch (err) {
      console.log(err)
      alert ('insufficient funds for gas price + value in wallet');
    }
  }

  const connectWalletButton = () => {
    return (
      <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
        Connect Wallet
      </button>
    )
  }

  const mintNftButton = () => {
    return (
      <>
        <div className='mint-amount'>
          <div>
            <h5>Price: 0.07 Eth + gas fee</h5>
          </div>
          <button onClick={mintNftHandler} className='cta-button mint-nft-button'>
            Mint a Capsule
          </button>
        </div>
      </>
    )
  }

  useEffect(() => {
    checkWalletIsConnected();
  }, [])

  return (
    <>
      <div className="hero-container">
        <div className="content-container">
          <h1>Capsule Corp.</h1>
          <p>Everything you need in a compact size</p>
          <div className="mint-area">
            <div className='connect-button' >
              {currentAccount ? mintNftButton() : connectWalletButton()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
