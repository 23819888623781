import React from 'react';
import '../Shop.css';
import '../Button.css';

function Shop() {
    return (
      <>
        <div className="common-container">
          <div className="mb-4 mb-lg-5 container">
            <img
              src="./images/capsule-corporation.png"
              className="capsulecorp"
              width="100%"
              height="600px"
              alt="capsulecorp"
            ></img>
          </div>
          <div className="px-4 mt-md-4 container">
            <div className="row">
              <div className="mb-4 row">
                <div className="col">
                  CPSL is a collection of Capsules. It is a unique NFT digital
                  collectible on the Ethereum blockchain produced by
                  CapsuleCorp.
                </div>
                <div className="my-lg-auto col-12 offset-lg-1">
                  <div className="common-container">
                    <img
                      src="./images/img-1.png"
                      className="capsule"
                      width="200px"
                      height="200px"
                      alt="capsule"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shop-container">
          <button className="shop-button">
            <a
              href="https://opensea.io/collection/capsule-corp"
              className="buy-button-style"
            >
              Collection
            </a>
          </button>
          <div className="shop-info">
            To obtain a Capsule go to homepage, connect wallet and mint 1. Or view the verified contract and mint up to 3!
          </div>
          <div className="shop-nft">Buy an NFT Now</div>
        </div>
        <div className="footer">
          <div className="container-fluid foot-line">
            <div className="row mx-0 footer-padding">
            <h2 className="col-12 col-lg-4 order-lg-first my-lg-auto">C P S L</h2>
              <div className="col-12 col-lg-4 order-first">
                <img className="img-fluid footer-logo" src="./images/logo.png" width="100px" height="100px" alt="logo"></img>
              </div>
              <div className="order-last my-auto text-center col-lg-4 col-sm-12 col-12">
                <div className="row">
                  <div className="text-lg-right col-sm-12">
                    <a href="https://www.instagram.com/cpslcorp">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://twitter.com/CpslCorp">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://t.co/9rMqjcDQam">
                      <i className="fab fa-discord"></i>
                    </a>
                  </div>
                  <div className="text-lg-right col-sm-12">
                    <p className="copyright text-right">
                      <span className="copy-left">© 2022 CpslCorp</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Shop;
